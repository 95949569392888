import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row, Card, Button, Table, Spin, message, PageHeader, Radio, Pagination } from 'antd';
import { ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
// import InfiniteScroll from 'react-infinite-scroll-component';
import RfdmeSelect from '../../components/Select';
import RfdmeCard from './card';
//
import appConfigs from '../../configs';
import useListOfPv from '../../hooks/useListOfPv';
import AuthContext from '../../contexts/authProvider';
import { IsValidAppSyncRsps } from '../../utils/valid';
import { AppSyncQueryTextFormat, SetKeyToArray, DigitToChineseFormat } from '../../utils/format';
import { GetFileNameFromResponseHeader, GetAppSyncRspsErrorMessage } from '../../utils/parse';
import { useTranslation } from 'react-i18next';

//
const RedDiv = styled.div`
  color: #ff4d4f;
  font-weight: bold;
`;
const GreenDiv = styled.div`
  color: #52c41a;
  font-weight: bold;
`;
const BlueDiv = styled.div`
  color: #1ea4d0;
  font-weight: bold;
`;

//設定
const INITIAL_FILTER_DATA = {};
const INIT_TABLE_INFO = { sortColumn: null, sortDirection: 'ascend', page: 1, pageSize: 20 };

const GenerateQueryConditions = (tableInfo, listType = 'list', pieChartView = false) => {
  //從 session storage 取出查詢條件
  const conditions = JSON.parse(window.sessionStorage.getItem('object-list-filterData'));

  //重組查詢參數
  let queryConditions = {};
  queryConditions.objectID = conditions.objectID;
  queryConditions.timezone = appConfigs.timezone;
  if (pieChartView) {
    if (conditions.ownerID) {
      const splitValues = conditions.ownerID.split('_');
      queryConditions.ownerID = splitValues[0];
      queryConditions.electricityPeriod = splitValues.length > 1 ? splitValues[1] : null;
    }
  } else {
    queryConditions.listType = listType;
    queryConditions.ownerID = conditions.ownerID;
  }
  if (tableInfo) {
    queryConditions.page = tableInfo.page;
    queryConditions.pageSize = tableInfo.pageSize;
    queryConditions.sortColumnName = tableInfo.sortColumn;
    queryConditions.sortDirection = tableInfo.sortDirection === 'ascend' ? 'asc' : 'desc';
    window.sessionStorage.setItem('object-list-tableInfo', JSON.stringify(tableInfo));
  }

  return queryConditions;
};

const BuildSqlQueryText = (investorView, externalView) => {
  let sqlText = `query OmQuery(
    $objectID: [String],
    $ownerID: [String],
    $region: String,
    $timezone: String!,
    $page: Int!,
    $pageSize: Int!,
    $sortColumnName: String,
    $sortDirection: String,
    $listType: String!
    ) { 
      om_getAllObjectDataList(
        objectID: $objectID,
        ownerID: $ownerID,
        region: $region,
        timezone: $timezone,
        page: $page,
        pageSize: $pageSize,
        sortColumnName: $sortColumnName,
        sortDirection: $sortDirection,
        listType: $listType
      ) {
        total
        list { 
          ownerObjectID
          objectID
          objectName
          region
          capacity
          companyID
          companyName
          totalEnergy
          lossEnergy
          alertExpired
          todoAlertAmount
          todoCidAmount
          todoOmAmount
          controllerWarning 
          inverterWarning
          healthWarning  
          moduleWashWarning
          moduleWashNoCidFlag 
          TotalWarning
          cumulativePR
          guaranteeAchieveRate
          capacityActivationRate
          capacityHealthRate
        }
      }
    }
  `;
  if (investorView) {
    sqlText = `query OmQuery(
      $objectID: String,
      $ownerID: String,
      $electricityPeriod: String,
      $timezone: String!,
      $page: Int!,
      $pageSize: Int!
      ) { 
        om_getAllObjectDataListByInvestor(
          objectID: $objectID,
          ownerID: $ownerID,
          electricityPeriod: $electricityPeriod,
          timezone: $timezone,
          page: $page,
          pageSize: $pageSize
        ) {
            total
            list { 
              ownerObjectID
              objectID
              objectName
              companyID
              companyName
              electricityPeriod
              cumulativePR
              majorFlag
              sunlightCalculateType
              virtualPR
            }
          }
        }
    `;
  } else if (externalView) {
    sqlText = `query OmQuery(
      $objectID: String,
      $ownerID: String,
      $electricityPeriod: String,
      $timezone: String!
      $page: Int!,
      $pageSize: Int!
      ) { 
        om_getAllObjectDataListByExternal(
          objectID: $objectID,
          ownerID: $ownerID,
          electricityPeriod: $electricityPeriod,
          timezone: $timezone,
          page: $page,
          pageSize: $pageSize
        ) {
            total
            list { 
              ownerObjectID
              objectID
              objectName
              companyID
              companyName
              electricityPeriod
              cumulativePR
              majorFlag
            }
          }
        }
    `;
  }

  return AppSyncQueryTextFormat(sqlText);
};

//主要元件
const ObjectList = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  //
  const permissions = useMemo(() => {
    const result = {
      canTriggerAlertsLink: false,
      canExportObjectStatistics: false,
      usePieChartView: false,
    };
    if (!user) return result;
    //
    result.canTriggerAlertsLink = user.operations.includes('OBJECTS_trigger-alerts-link');

    // result.canExportObjectStatistics = user.operations.includes('OBJECTS_export-object-statistics');
    result.canExportObjectStatistics = false;

    // 投資人及外部人員都是看圓餅圖
    result.usePieChartView =
      user.operations.includes('OBJECTS_investor-view') || user.operations.includes('OBJECTS_external-view');
    result.isInvestor = user.operations.includes('OBJECTS_investor-view');
    result.isExternal = user.operations.includes('OBJECTS_external-view');

    return result;
  }, [user]);
  //
  const [tableInfo, setTableInfo] = useState(INIT_TABLE_INFO);
  const [filterData, setFilterData] = useState(INITIAL_FILTER_DATA);
  const [dataList, setDataList] = useState({ total: 0, list: [] });
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [listType, setListType] = useState('card');
  const [alertList, setAlertList] = useState([]);
  const { data: spvPvList } = useListOfPv(true);
  // 限定的太陽能案場清單
  const solarPlantList = useMemo(() => {
    if (!Array.isArray(spvPvList)) return [];
    return spvPvList.filter(
      (f) =>
        f.objectType === 'SolarPlant' &&
        f.state !== 'C' &&
        (!f.majorFlag || (f.majorFlag === true && f.projectID !== f.value))
    );
  }, [spvPvList]);
  // 案場篩選條件 - 選項
  const pvList = useMemo(() => {
    if (!Array.isArray(solarPlantList) || solarPlantList.length === 0) return [];

    let result = _.cloneDeep(solarPlantList);

    if (filterData.ownerID) {
      let filterOwnerID = Array.isArray(filterData.ownerID) ? filterData.ownerID : [filterData.ownerID];
      if (filterOwnerID.length > 0) {
        result = result.filter((f) => {
          // 檢查 ownerID 陣列中的每個值
          return filterOwnerID.some((id) => {
            const splitValues = id.split('_');
            const ownerID = splitValues[0];
            const electricityPeriod = splitValues.length > 1 ? splitValues[1] : null;

            // 過濾邏輯
            if (electricityPeriod) {
              return (
                f.ownerID === ownerID &&
                f.electricityPeriod === (electricityPeriod === 'N' ? null : electricityPeriod)
              );
            } else {
              return f.ownerID === ownerID;
            }
          });
        });
      }
    }
    return result.map((m) => ({ label: m.label, value: m.value }));
  }, [solarPlantList, filterData.ownerID]);
  // SPV篩選條件 - 選項
  const ownerList = useMemo(() => {
    if (!Array.isArray(solarPlantList) || solarPlantList.length === 0) return [];

    return _.chain(solarPlantList)
      .map((m) => {
        if (permissions.usePieChartView) {
          if (!m.electricityPeriod) {
            if (!m.majorFlag) {
              return { label: m.ownerName, value: m.ownerID + '_', seq: 1, seqName: m.ownerName };
            } else {
              return {
                label: m.ownerName + '-' + t('objectList.unphased'),
                value: m.ownerID + '_N',
                seq: 999,
                seqName: m.ownerName,
              };
            }
          } else {
            return {
              label:
                m.ownerName +
                '-' +
                t('objectList.phase', { phase: DigitToChineseFormat(m.electricityPeriod) }),
              value: m.ownerID + '_' + m.electricityPeriod,
              seq: _.toNumber(m.electricityPeriod),
              seqName: m.ownerName,
            };
          }
        } else {
          return { label: m.ownerName, value: m.ownerID, seq: 1, seqName: m.ownerName };
        }
      })
      .filter((f) => f.value)
      .unionBy('value')
      .sortBy(['seqName', 'seq'])
      .map((m) => ({ label: m.label, value: m.value }))
      .value();
  }, [solarPlantList, permissions.usePieChartView, t]);

  // ======================================================

  useEffect(() => {
    if (!isFirstLoad) return;

    //
    const customOwnerID = location?.state?.ownerID || '';
    if (customOwnerID) {
      window.sessionStorage.setItem('object-list-filterData', JSON.stringify({ ownerID: customOwnerID }));
      window.sessionStorage.setItem('object-list-tableInfo', JSON.stringify(INIT_TABLE_INFO));
    }

    //
    const filterDataStringInSession = window.sessionStorage.getItem('object-list-filterData');
    if (filterDataStringInSession) {
      const conditions = JSON.parse(filterDataStringInSession);
      setFilterData(conditions);
    } else {
      window.sessionStorage.setItem('object-list-filterData', JSON.stringify(INITIAL_FILTER_DATA));
    }

    //
    const tableInfoStringInSession = window.sessionStorage.getItem('object-list-tableInfo');
    if (tableInfoStringInSession) {
      const info = JSON.parse(tableInfoStringInSession);
      setTableInfo(info);
    } else {
      window.sessionStorage.setItem('object-list-tableInfo', JSON.stringify(INIT_TABLE_INFO));
    }

    setIsFirstLoad(false);
  }, [isFirstLoad, location]);

  const handleFilterDataChange = (name) => (value) => {
    const modifyValue = value;
    const tmp = { ...filterData };
    if (name === 'ownerID' && tmp.objectID && (!modifyValue || tmp.ownerID !== modifyValue)) {
      delete tmp.objectID;
    }
    tmp[name] = modifyValue;
    setFilterData(tmp);
  };
  const handleReset = () => {
    setFilterData({ ...INITIAL_FILTER_DATA });
    window.sessionStorage.setItem('object-list-filterData', JSON.stringify(INITIAL_FILTER_DATA));
    setTableInfo((tbi) => ({ ...tbi, page: 1 }));
  };
  const handleSearch = () => {
    window.sessionStorage.setItem('object-list-filterData', JSON.stringify(filterData));
    setTableInfo((tbi) => ({ ...tbi, page: 1 }));
  };
  const handleQuery = useCallback(() => {
    if (!user) return;
    //
    const queryConditions = GenerateQueryConditions(tableInfo, listType, permissions.usePieChartView);
    const sqlText = BuildSqlQueryText(permissions.isInvestor, permissions.isExternal);
    //查詢
    setLoading(true);
    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: sqlText,
          variables: { ...queryConditions },
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('objectList.failedToLoadList', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        const tmp = permissions.isInvestor
          ? res.data.data.om_getAllObjectDataListByInvestor
          : permissions.isExternal
          ? res.data.data.om_getAllObjectDataListByExternal
          : res.data.data.om_getAllObjectDataList;
        tmp.list = tmp.list.sort((a, b) => b.TotalWarning - a.TotalWarning).map(SetKeyToArray);
        setDataList(tmp);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load all object list error', err);
          message.error(t('objectList.loadListError'));
        }
      })
      .then(() => {
        setLoading(false);
      });

    //
  }, [user, tableInfo, listType, permissions, t]);

  //獲取案場紅綠燈的警告代碼清單
  useEffect(() => {
    if (!user || permissions.usePieChartView) return;

    axios
      .post(
        appConfigs.appSyncURL,
        {
          query: AppSyncQueryTextFormat(
            `query OmQuery {
              om_getObjectWarningLightAlertIDList {
                key 
                alertIDs 
               }
             }
            `
          ),
          variables: null,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (!IsValidAppSyncRsps(res)) {
          message.warn(t('objectList.failedToGetWarningCode', { error: GetAppSyncRspsErrorMessage(res) }));
          return;
        }

        setAlertList(res.data.data.om_getObjectWarningLightAlertIDList || []);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error('load object warning list error', err);
          message.error(t('objectList.getWarningCodeError'));
        }
      });
  }, [user, permissions.usePieChartView, t]);

  useEffect(() => {
    if (!isFirstLoad) handleQuery();
  }, [isFirstLoad, handleQuery]);

  const handleCardChange = (page) => {
    window.sessionStorage.setItem('object-list-tableInfo', JSON.stringify({ ...tableInfo, page }));
    setTableInfo((tbi) => ({
      ...tbi,
      page,
    }));
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      window.sessionStorage.setItem(
        'object-list-tableInfo',
        JSON.stringify({ ...tableInfo, page: pagination.current })
      );
      setTableInfo((tbi) => ({
        ...tbi,
        page: pagination.current,
      }));
    }

    if (extra.action === 'sort') {
      window.sessionStorage.setItem(
        'object-list-tableInfo',
        JSON.stringify({ ...tableInfo, sortColumn: sorter.field, sortDirection: sorter.order })
      );
      setTableInfo((tbi) => ({
        ...tbi,
        sortColumn: sorter.field,
        sortDirection: sorter.order, //"ascend" or "descend"
      }));
    }
  };

  const handleExport = () => {
    //
    const queryConditions = GenerateQueryConditions(null);

    setExporting(true);
    axios
      .post(
        `${appConfigs.apiGatewayURL}/oms/object/export`,
        { ...queryConditions },
        { headers: { Authorization: user.token, Accept: '*/*' }, responseType: 'blob' }
      )
      .then((res) => {
        if (!res || !res.data) {
          message.error(t('objectList.exportFailed', { msg: res?.data?.msg }));
          return;
        }

        //
        const fileName = GetFileNameFromResponseHeader(res.headers['content-disposition'], '異常列表.xlsx');
        const blobFile = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobFile;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobFile);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          message.error(t('objectList.exportError'));
          console.error('匯出異常', err);
        }
      })
      .then(() => {
        setExporting(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        title: t('objectList.siteId'),
        dataIndex: 'ownerObjectID',
        key: 'ownerObjectID',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => <Link to={`/pvs/${value}`}>{value}</Link>,
      },
      {
        title: t('objectList.spv'),
        dataIndex: 'companyName',
        key: 'companyName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('objectList.siteName'),
        dataIndex: 'objectName',
        key: 'objectName',
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: t('objectList.prValue'),
        dataIndex: 'cumulativePR',
        key: 'cumulativePR',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => (value ? _.round(value, 2) + ' %' : '-'),
      },
      {
        title: t('objectList.guaranteedPowerAchievementRate'),
        dataIndex: 'guaranteeAchieveRate',
        key: 'guaranteeAchieveRate',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => (value ? _.round(value, 2) + ' %' : '-'),
      },
      {
        title: t('objectList.inverterCapacityActivationRate'),
        dataIndex: 'capacityActivationRate',
        key: 'capacityActivationRate',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => (value ? _.round(value, 2) + ' %' : '-'),
      },
      {
        title: t('objectList.inverterCapacityHealthRate'),
        dataIndex: 'capacityHealthRate',
        key: 'capacityHealthRate',
        sorter: true,
        showSorterTooltip: false,
        render: (value) => (value ? _.round(value, 2) + ' %' : '-'),
      },
      {
        title: t('objectList.pendingAlerts'),
        dataIndex: 'todoAlertAmount',
        key: 'todoAlertAmount',
        sorter: true,
        showSorterTooltip: false,
        width: 120,
        render: (value) => <RedDiv>{value}</RedDiv>,
      },
      {
        title: t('objectList.pendingCids'),
        dataIndex: 'todoCidAmount',
        key: 'todoCidAmount',
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        render: (value) => <GreenDiv>{value}</GreenDiv>,
      },
      {
        title: t('objectList.pendingOms'),
        dataIndex: 'todoOmAmount',
        key: 'todoOmAmount',
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        render: (value) => <BlueDiv>{value}</BlueDiv>,
      },
    ];
  }, [t]);

  return (
    <>
      {/* page header */}
      <PageHeader
        className="app-page-header rwd-btn-wrap"
        title={t('objectList.title')}
        extra={[
          <div key="1">
            {!permissions.usePieChartView && (
              <Radio.Group
                defaultValue={listType}
                value={listType}
                onChange={(e) => {
                  setListType(e.target.value);
                  setTableInfo(INIT_TABLE_INFO);
                }}
              >
                <Radio.Button value="card">{t('objectList.cardView')}</Radio.Button>
                <Radio.Button value="list">{t('objectList.listView')}</Radio.Button>
              </Radio.Group>
            )}
          </div>,
          <div key="2">
            {!permissions.usePieChartView && (
              <Button type="text" icon={<ReloadOutlined />} onClick={handleSearch} key="2" />
            )}
          </div>,
          <div key="3">
            {permissions.canExportObjectStatistics && (
              <Button type="text" icon={<DownloadOutlined />} loading={exporting} onClick={handleExport} />
            )}
          </div>,
        ]}
      />

      {/* 搜尋面板 */}
      <div className="app-page-searchpanel">
        <Card size="small">
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <RfdmeSelect
                showSearch
                mode={permissions.usePieChartView ? '' : 'multiple'}
                placeholder={t('objectList.site')}
                options={pvList}
                value={filterData.objectID}
                onChange={handleFilterDataChange('objectID')}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <RfdmeSelect
                showSearch
                mode={permissions.usePieChartView ? '' : 'multiple'}
                placeholder={t('objectList.spv')}
                options={ownerList}
                value={filterData.ownerID}
                onChange={handleFilterDataChange('ownerID')}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={{ span: 3, offset: 6 }} xl={{ span: 3, offset: 6 }}>
              <Button block onClick={handleReset}>
                {t('objectList.reset')}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <Button type="primary" block onClick={handleSearch}>
                {t('objectList.search')}
              </Button>
            </Col>
          </Row>
        </Card>
      </div>

      {/* 列表 */}
      <div className="app-page-content">
        <Spin spinning={loading} tip={t('objectList.searching')} style={{ minHeight: 300 }}>
          {permissions.usePieChartView ? (
            <>
              <Row gutter={[12, 12]}>
                {dataList.list.map((item) => (
                  <RfdmeCard key={item.ownerObjectID} item={item} investorView={true} />
                ))}
              </Row>
              <div style={{ textAlign: 'right', margin: '12px 0px' }}>
                <Pagination
                  size="small"
                  total={dataList.total}
                  pageSize={tableInfo.pageSize}
                  showSizeChanger={false}
                  current={tableInfo.page}
                  onChange={handleCardChange}
                />
              </div>
            </>
          ) : (
            <>
              {listType === 'card' ? (
                <>
                  <Row gutter={[12, 12]}>
                    {dataList.list.map((item) => (
                      <RfdmeCard
                        key={item.ownerObjectID}
                        item={item}
                        alertList={alertList}
                        alertLink={permissions.canTriggerAlertsLink}
                      />
                    ))}
                  </Row>
                </>
              ) : (
                <Card size="small">
                  <Table
                    // bordered
                    size="small"
                    columns={columns}
                    dataSource={dataList.list}
                    scroll={{ x: 1600, y: 'calc(100vh - 410px)' }}
                    pagination={{
                      total: dataList.total,
                      pageSize: tableInfo.pageSize,
                      showSizeChanger: false,
                      current: tableInfo.page,
                    }}
                    onChange={handleTableChange}
                  />
                </Card>
              )}
            </>
          )}
        </Spin>
      </div>
    </>
  );
};

export default ObjectList;
