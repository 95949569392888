import React, { useContext } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Button, Divider, Tooltip } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { NumberFormat } from '../../utils/format';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
//
import AuthContext from '../../contexts/authProvider';

const SemiCircleProgress = ({ value, fillColor }) => {
  const data = [{ value: value || 0 }, { value: 1 - (value || 0) }];

  return (
    <ResponsiveContainer width="100%" height={75}>
      <PieChart>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          outerRadius={65}
          innerRadius={44}
          fill="#8884d8"
          cy="100%"
        >
          <Cell key="filled" fill={fillColor} strokeWidth={0} />
          <Cell key="unfilled" fill="rgba(155,155,155,0.12)" strokeWidth={0} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

//
const ValueFormatter = (value) => {
  if (!value) return '--%';
  return _.round(value * 100, 2) + '%';
};

function digitToChinese(digit) {
  // 如果傳入的是null，則直接回傳空字串
  if (digit === null) {
    return '';
  }

  // 嘗試將傳入的值轉換為數字
  const num = parseInt(digit, 10);

  // 如果轉換後的結果是NaN（不是一個數字），則回傳空字串
  if (isNaN(num)) {
    return '';
  }

  const chineseDigits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];

  // 如果數字在0到9之間，則回傳對應的中文數字
  if (num >= 0 && num <= 9) {
    return chineseDigits[num];
  } else {
    // 如果數字不在0到9之間，則回傳空字串
    return '';
  }
}

// 主要呈現
const CardItem = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const periodName = digitToChinese(data.electricityPeriod);
  const spvName = data.majorFlag
    ? `${data.spvName} ${periodName ? t('spvList.card.phase', { phase: periodName }) : ''}`
    : data.spvName;
  const viewPR = user.operations.includes('SPVLIST_investor-view')
    ? data.sunlightCalculateType === 'G'
      ? data.virtualPR
      : data.weatherStationPR
    : data.virtualPR;
  const isPromisePR = data?.promiseType === 'B'; //維運是否保PR

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
      <Card
        size="small"
        className="card-alert"
        style={{ height: '100%' }}
        title={spvName || t('spvList.card.unknown')}
        extra={
          <Link
            to={`/spvlist/${spvName}`}
            state={{ companyID: data.spvID, electricityPeriod: data.electricityPeriod }}
          >
            <Button type="link" size="small">
              {t('spvList.card.details')}
            </Button>
          </Link>
        }
      >
        <Row justify="center" style={{ marginBottom: 4 }}>
          <Col>
            <span className="spv-title">{t('spvList.card.yearlyPowerIncome')}</span>
            <span className="spv-value">{NumberFormat(data.powerIncome && data.powerIncome.toFixed(0))}</span>
            <span className="spv-unit">NTD</span>
            <div className="app-hint">
              <Tooltip title={t('spvList.card.yearlyPowerIncomeTooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <span className="spv-title">{t('spvList.card.yearlyAccumulatedPower')}</span>
            <span className="spv-value">
              {NumberFormat(data.powerGeneration && data.powerGeneration.toFixed(2))}
            </span>
            <span className="spv-unit">kWh</span>
            <div className="app-hint">
              <Tooltip title={t('spvList.card.yearlyAccumulatedPowerTooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <span className="spv-title">{t('spvList.card.installedCapacity')}</span>
            <span className="spv-value">{NumberFormat(data.capacity && data.capacity.toFixed(2))}</span>
            <span className="spv-unit">kWp</span>
          </Col>
        </Row>
        <Divider dashed />

        {/* 版本一
        <Row>
          <Row2RelativeCol span={8}>
            <Row2Title>PR值</Row2Title>
            <SemiCircleProgress value={data.averagePR} fillColor="#1EA4D0" />
            <Row2PieChartLabel>{ValueFormatter(data.averagePR)}</Row2PieChartLabel>
          </Row2RelativeCol>
          <Row2RelativeCol span={8}>
            <Row2Title>保發達成率</Row2Title>
            <SemiCircleProgress value={data.averageGuaranteeAchieve} fillColor="#13C2C2" />
            <Row2PieChartLabel>{ValueFormatter(data.averageGuaranteeAchieve)}</Row2PieChartLabel>
          </Row2RelativeCol>
          <Row2RelativeCol span={8}>
            <Row2Title>預期保發達成率</Row2Title>
            <SemiCircleProgress value={data.averageExpectedGuaranteeAchieve} fillColor="#52C41A" />
            <Row2PieChartLabel>{ValueFormatter(data.averageExpectedGuaranteeAchieve)}</Row2PieChartLabel>
          </Row2RelativeCol>
        </Row> 
        */}

        {/* 版本二 */}
        {data.majorFlag && (
          <Row justify="center">
            <Col>
              <span className="spv-title">{data.guaranteeStartDate + ' ~ ' + data.guaranteeEndDate}</span>
              <div className="app-hint">
                <Tooltip title={t('spvList.card.statisticsTooltip')}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={[4, 12]} justify="center">
          <Col xs={24} sm={24} md={24} lg={8}>
            <div className="spv-chart-wrap">
              <div className="spv-chart-title">
                {t('spvList.card.accumulatedPR')}
                <div className="app-hint">
                  <Tooltip
                    title={
                      <div>
                        {user.operations.includes('SPVLIST_investor-view') &&
                        data.sunlightCalculateType !== 'G'
                          ? t('spvList.card.prValueTooltip')
                          : t('spvList.card.prValueTooltipG')}
                      </div>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              </div>
              <SemiCircleProgress value={viewPR} fillColor="#1EA4D0" />
              <div className="spv-chart-value">{ValueFormatter(viewPR)}</div>
            </div>
          </Col>
          {!isPromisePR && (
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="spv-chart-wrap">
                <div className="spv-chart-title">
                  {t('spvList.card.guaranteedPowerAchievementRate')}
                  <div className="app-hint">
                    <Tooltip title={t('spvList.card.guaranteedPowerAchievementRateTooltip')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                  <SemiCircleProgress value={data.guaranteeAchieve} fillColor="#13C2C2" />
                  <div className="spv-chart-value">{ValueFormatter(data.guaranteeAchieve)}</div>
                </div>
              </div>
            </Col>
          )}
          {!isPromisePR && (
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="spv-chart-wrap">
                <div className="spv-chart-title">
                  {t('spvList.card.expectedGuaranteedPowerAchievementRate')}
                  <div className="app-hint">
                    <Tooltip title={t('spvList.card.expectedGuaranteedPowerAchievementRateTooltip')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                  <SemiCircleProgress value={data.expectedGuaranteeAchieve} fillColor="#52C41A" />
                  <div className="spv-chart-value">{ValueFormatter(data.expectedGuaranteeAchieve)}</div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
};

export default CardItem;
